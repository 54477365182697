import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Summary from "../views/Summary.vue"
import { component } from "vue/types/umd";
import Monumenti from "@/views/Monumenti.vue";
import MonumentoDetails from "@/views/MonumentoDetails.vue";
//import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

export default new Router({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    //HOME
    {
      path: "/",
      name: "Home",
      component: Home
    },
    //ABOOUT
    {
      path: "/about",
      name: "About",
      component: About
    },
    //SUMMARY
    {
      path:"/summary",
      name:"Summary",
      component : Summary
    },
    //LISTA MONUMENTI
    {
      path :"/monuments",
      name:"Monuments",
      component:Monumenti
    },
    //DETTAGLIO MONUMENTO
    {
      path :"/details-monument",
      name:"DetailsMonument",
      component:MonumentoDetails
    }
  ]
})