import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

@Component
export default class SimpleKeyboard extends Vue {
	@Prop({ type: String, default: "simple-keyboard" }) readonly keyboardClass: any;
	@Prop(String) readonly input: any;
	@Prop(String) readonly inputName: any;

	keyboard: any = null;

	mounted() {
		this.keyboard = new Keyboard({
			onChange: this.onChange,
			onKeyPress: this.onKeyPress,
			preventMouseDownDefault: true,
			tabCharOnTab: false,
			inputName: this.inputName,
			layoutName: "shift",
			layout: {
				default: ["` 1 2 3 4 5 6 7 8 9 0 - = {bksp}", "{tab} q w e r t y u i o p [ ] \\", "{lock} a s d f g h j k l ; ' {enter}", "{shift} z x c v b n m , . / {shift}", ".com @ {space}"],
				defaultNumber: ["1 2 3", "4 5 6", "7 8 9", "0", "{tab} {bksp} {enter}"],
				shift: ["` 1 2 3 4 5 6 7 8 9 0 - = {bksp}", "{tab} Q W E R T Y U I O P { } |", '{lock} A S D F G H J K L : " {enter}', "{shift} Z X C V B N M < > ? {shift}", ".com @ {space}"]
			},
			mergeDisplay: true,
			display: {
				'{bksp}': 'cancella',
				'{enter}': 'conferma',
			  }
		});
	}

	onChange(input: any) {
		this.$emit("onChange", input);
	}
	onKeyPress(button: any) {
		this.$emit("onKeyPress", button);

		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === "{shift}" || button === "{lock}") this.handleShift();
	}
	handleShift() {
		let currentLayout = this.keyboard.options.layoutName;
		let shiftToggle = currentLayout === "default" ? "shift" : "default";

		this.keyboard.setOptions({
			layoutName: shiftToggle
		});
	}

	@Watch("input")
	onPropertyInputChanged(value: string, oldValue: string) {
		this.keyboard.setInput(value);
	}
	@Watch("inputName")
	onPropertyInputNameChanged(value: string, oldValue: string) {
		
		this.keyboard.setOptions({ value });

		let layout = "shift";
		if (value == "mobilephone") layout = "defaultNumber";
		this.keyboard.setOptions({
			layoutName: layout
		});
	}
}
