import { bus } from "@/main";
import store from "@/store";
import { Component, Vue, Prop} from "vue-property-decorator";
import api from "../services/api-service";

@Component
export default class MonumentoDetails extends Vue{

    nameMonument: any = null;
    imageMonument :object[] = [];
    infoMonument : string = "";
    mapUri : string = "";
    qrCode : string = "";
    descrizione : string = "";
    loadingMonument : boolean = true;

    
    listImgFotoMonumenti : any = []
    mounted(){
        this.getDetailsMonument();
        this.nameMonument = this.$route.query.name
        this.listImgFotoMonumenti = store.state.imageMonument
        
        this.infoMonument = store.state.infoMonument
        bus.$emit("onGoToAutoHomeEvent", 60000);
    }
    getDetailsMonument(){

        var self = this;

        var data = {
                 
            cemeteryId :process.env.VUE_APP_CEMETERY_ID,
            totemId : process.env.VUE_APP_TOTEM_ID,
            securityToken : process.env.VUE_APP_SECURITY_TOKEN,
            codPoi : this.$route.query.cod_Poi
        }
        
        api.getMonumento(data)
            .then(result => {               
                let response = result.data;
            
                self.mapUri = response.Records[0].mapUri;
                self.qrCode = response.Records[0].qrcodeUri;
                self.descrizione = response.Records[0].descrizione;
                
                self.loadingMonument=false;

            }).catch(err => {
                console.log(err);

            })
        
        
    }
}