import api from "../services/api-service";
import { Component, Vue } from "vue-property-decorator";
import { TombaItem } from "@/models/Tomba/TombaItem";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import { bus } from "@/main";


@Component({
    computed: { ...mapGetters(["getMortiList"])}
})
export default class Summary extends Vue {

    firstname : string  = "";
    lastname : string  = "";
    displayName : string = "";
    mapUri : string | null = "";
    qrCode : string | null = "";
    loading : boolean = true;
    dob : string = "";
    dod : string = "";
    type : string = "";
    zona : string = "";
    column : number = 0;
    row : number = 0;
    plane : string = "";

    dataMorti : TombaItem[] =  []
    
    
    mounted(){    
        
        
        if(store.state.mortiList.length === 1){
            this.dataMorti = store.state.mortiList
            this.firstname = this.dataMorti[0].firstName
            this.lastname = this.dataMorti[0].lastName
            this.displayName = this.dataMorti[0].displayName
            this.mapUri = this.dataMorti[0].mapUri
            this.qrCode = this.dataMorti[0].qrcodeUri
            this.dob = this.dataMorti[0].dob.toString();
            this.dod = this.dataMorti[0].dod.toString();
            this.type = this.dataMorti[0].type;
            this.zona = this.dataMorti[0].zone;
            this.column = this.dataMorti[0].column;
            this.row = this.dataMorti[0].row;
            this.plane = this.dataMorti[0].plane;
            this.loading = false;
        }
        else{
            
            
            this.getMorto();    
            
        }
        bus.$emit("onGoToAutoHomeEvent", 60000);
    }
    getMorto(){
        
        var self = this;

        
        var data = {
            firstName : this.$route.query.firstname,
            familyName :this.$route.query.lastname,       
            cemeteryId :process.env.VUE_APP_CEMETERY_ID,
            totemId : process.env.VUE_APP_TOTEM_ID,
            securityToken : process.env.VUE_APP_SECURITY_TOKEN,
            graveId : this.$route.query.graveid
        }
        
        api.getTomba(data)
            .then(result => {               
                

                let response = result.data;
                
                self.firstname = response.Records[0].firstName;
                self.lastname = response.Records[0].lastName;
                self.displayName = response.Records[0].displayName;
                self.mapUri = response.Records[0].mapUri;
                self.qrCode = response.Records[0].qrcodeUri;
                self.dob = response.Records[0].dob.toString();
                self.dod = response.Records[0].dod.toString();
                self.type = response.Records[0].type;
                self.zona = response.Records[0].zone;
                self.column = response.Records[0].column;
                self.row = response.Records[0].row;
                self.plane = response.Records[0].plane;

                self.loading = false;

            }).catch(err => {
                console.log(err);

            })
        
    }

    goToSummary(firstName:string, lastName:string){
        this.$router.push({name:"Summary", query:{firstname : firstName,lastname:lastName}})
    }
    
    
}
