import { TombaItem } from "@/models/Tomba/TombaItem";
import { VuexState } from "@/models/VuexState";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store<VuexState>({
  state: {
    mortiList :  [],
    imageMonument: [],
    infoMonument : "",
    storeLastname : ""
  },
  mutations: {
    setMortiList : ( state:VuexState, data : TombaItem[]) =>{
      state.mortiList = data
      
    },
    setLastname : ( state:VuexState, lastname : string) =>{
      state.storeLastname = lastname
      
    },
    resetMortiList : (state:VuexState) =>{
      state.mortiList = []
      state.storeLastname = ""
    },
    setImageMonument : (state:VuexState, imgStorePath : any, ) =>{
      
      state.imageMonument = imgStorePath      
    },
    setInfoMonument :(state:VuexState, info:string) => {
      state.infoMonument = info
    }
  },
  getters : {
    getMortiList : (state:VuexState) =>{
      return state.mortiList
    }
  },
  actions: {},
  modules: {},
});