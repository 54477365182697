import SimpleKeyboard from "../components/SimpleKeyboard.vue";
import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { bus } from "@/main";


@Component({
    components :{
         SimpleKeyboard
    }
})
export default class Home extends Vue {
    firstname: string = "";
	lastname: string = "";
    
    //Keyboard
    input: string = "";
	inputName: string = "txtlastName";
    goToHomeAutoWithoutActionTimeout: any = null;

    

    created(){
        // bus.$on("onGoToAutoHomeEvent", this.restartAutoGoToHome.bind(this));
    }

    mounted(){
        store.commit("resetMortiList")
    }
    cercaMorti(){
        this.firstname != ""? this.$router.push({name : "About", query:{firstname : this.firstname,lastname:this.lastname}}) : this.$router.push({name : "About", query:{lastname:this.lastname}}) ;
    }
    listaMonumenti(){
        this.$router.push({
            name:"Monuments"
        })
    }
    rules = {
        required(value: any, isRequired: boolean, message: string): any {
			return !isRequired || !!value || message;
		}
    }
    get inputValue(): string {
		return (this as any)[this.inputName];
	}
    onChange(input: any) {        
		(this as any)[this.inputName] = input;
	}
    onKeyPress(button: any) {
		if (button === "{enter}") {
			this.inputName = "";
			// (document.activeElement as any).blur();
			// this.findBook();
		}
	}
	onInputChange(input: any) {
		if (input.target) (this as any)[this.inputName] = input.target.value;
		else (this as any)[this.inputName] = input;
	}
	
	onInputFocus(input: any) {
        const keyboard = document.getElementById("keyboard") as HTMLDivElement;
        keyboard.removeAttribute("style");
		this.inputName = input.target.id;
	}
    restartAutoGoToHome(time: number) {
		var self = this;
		console.log("Called restartAutoGoToHome: " + time);		
		clearTimeout(self.goToHomeAutoWithoutActionTimeout);
		self.goToHomeAutoWithoutActionTimeout = setTimeout(function () {
			console.log("Called goToHomeAutoWithoutActionTimeout: " + time);
			self.$router.push({name:"Home"});
		}, time);
	}

	
    
}