
import { Component, Vue } from "vue-property-decorator";

import Header from "./components/Header.vue";

import { bus } from "@/main";


@Component({
    components :{
        Header
   }
   
})
export default class App extends Vue {

    goToHomeAutoWithoutActionTimeout: any = null;

    created(){

        bus.$on("onGoToAutoHomeEvent", this.restartAutoGoToHome.bind(this));
    }
    
    restartAutoGoToHome(time: number) {
		var self = this;
		console.log("Called restartAutoGoToHome: " + time);		
		clearTimeout(self.goToHomeAutoWithoutActionTimeout);
		self.goToHomeAutoWithoutActionTimeout = setTimeout(function () {
			console.log("Called goToHomeAutoWithoutActionTimeout: " + time);
			self.$router.push({name:"Home"});
		}, time);
	}
}
