import { bus } from "@/main";
import { Component, Vue, Prop} from "vue-property-decorator";
import ButtonList from "../components/ButtonList.vue";
const  data = require("../store/monumenti/monumenti.json");


@Component({
    components :{
         ButtonList
    }
})
export default class Monumenti extends Vue {
    monumenti : object = data
    mounted(){
        bus.$emit("onGoToAutoHomeEvent", 60000);
        
    }
    movePageDown () {
        window.scrollBy(0, window.innerHeight);
    }
    movePageUp(){
        window.scrollBy(0, -window.innerHeight);
    }
}
