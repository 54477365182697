import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import "@/assets/css/style.css";

export const bus = new Vue();

Vue.config.productionTip = false;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

Vue.use(VueAxios, axiosInstance);


new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
