



















import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ButtonList extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) cod_poi!: string;
  @Prop(String) imgFolder!: string;
  @Prop(String) info!: string;
  @Prop(Array) listImage!: [];

  testImage: string = "../assets/img/FOTO/AdolfoWildt.jpg";
  goToDetails() {
    store.commit("setImageMonument", this.listImage);
    store.commit("setInfoMonument", this.info);
    this.$router.push({
      name: "DetailsMonument",
      query: { name: this.label, cod_Poi: this.cod_poi },
    });
  }
}
