import api from "../services/api-service";
import { Component, Vue } from "vue-property-decorator";
import { TombaItem } from "@/models/Tomba/TombaItem";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store"
import {bus} from "@/main"


@Component({
    computed: { ...mapGetters(["getMortiList"]) },
	methods: { ...mapMutations(["setMortiList"]) }
})
export default class About extends Vue {

    firstname : any  = null;
    lastname : string  = "";
    loading : boolean = true;

    
    listMorti : TombaItem [] = [];
    ListaMortiTempPagined : TombaItem []  = []

    
    headers = [
            {
                text:'Nominativo', value :"displayName" 
            },    
            {
                text:'Data di Nascita', value :"dob" 
            },
            {
                text:'Data di Morte', value :"dod" 
            }
        ]

    //pagination
    startPage=0;
    endPage = 10;
    limitSupNumberMortiList : boolean = false;
    limitInfNumberMortiList : boolean = true;
    totalPageNumber : number = 0;
    currentPage : number = 1;
    


    mounted(){     
        if(store.state.mortiList.length === 0){
            this.getMorti();
            store.commit("setLastname",this.$route.query.lastname )
            
            
        }
            
        else{
           
            this.listMorti = store.state.mortiList
            this.ListaMortiTempPagined = this.listMorti
            this.loading = false;
            if(store.state.mortiList.length <= 10){
                this.limitSupNumberMortiList = true;
                this.limitInfNumberMortiList = true;
                this.totalPageNumber = 1;
                
            }
            else{
                this.totalPageNumber = store.state.mortiList.length%2 !=0 ? Math.floor(store.state.mortiList.length/10) +1 : Math.floor(store.state.mortiList.length/10)
            }
        }

        this.lastname = store.state.storeLastname
        this.firstname = this.$route.query.firstname
        

        bus.$emit("onGoToAutoHomeEvent", 60000);
            
    }
  
    getMorti(){

        var self = this;


        var data = {
            firstName : this.$route.query.firstname,
            familyName :this.$route.query.lastname,       
            cemeteryId :process.env.VUE_APP_CEMETERY_ID,
            totemId : process.env.VUE_APP_TOTEM_ID,
            securityToken : process.env.VUE_APP_SECURITY_TOKEN,
        }
        
        
        api.getTomba(data)
            .then(result => {            
                
                let response = result.data;           
                self.listMorti = response.Records ===null ? [] : response.Records;
                self.ListaMortiTempPagined = response.Records ===null ? [] : response.Records;
                store.commit("setMortiList", self.listMorti)
                if(response.totalNumberOfResults <= 10){
                    this.limitSupNumberMortiList = true;
                    this.limitInfNumberMortiList = true;
                    this.totalPageNumber = 1
                    
                }
                else{
                    this.totalPageNumber = response.totalNumberOfResults%2 !=0 ? Math.floor(response.totalNumberOfResults/10) +1 : Math.floor(response.totalNumberOfResults/10)
                    
                    
                }
                if(response.totalNumberOfResults === 1 ){
                    
                    this.$router.push({name:"Summary"})
                }
                self.loading = false;
                


            }).catch(err => {
                console.log(err);

            })
                
            
        
    }

    goToSummary(firstName:string, lastName:string){
        this.$router.push({name:"Summary", query:{firstname : firstName,lastname:lastName}})
    }

    handleClick(item : any){        
        //console.log(item.firstName,item.lastName);
        this.$router.push({name:"Summary", query:{graveid:item.cod_tomba,firstname:item.firstName,lastname:item.lastName}})
        
        
    }
    paginationAvanti(){
        

        if( this.limitSupNumberMortiList){            
            this.ListaMortiTempPagined = this.listMorti.slice(this.startPage, this.listMorti.length)
            
        }
        else{
            this.limitInfNumberMortiList = false;
            this.startPage += 10;
            this.endPage += 10;
            this.currentPage += 1
            this.ListaMortiTempPagined = this.listMorti.slice(this.startPage, this.endPage);
            if(this.endPage >= this.listMorti.length){
                this.limitSupNumberMortiList = true
                
            }
        }
        
        
    }

    paginationIndietro(){
        if( this.limitInfNumberMortiList){
            this.ListaMortiTempPagined = this.listMorti.slice(0,10)
            
        }
        else{
            this.limitSupNumberMortiList = false;
            this.startPage -= 10;
            this.endPage -= 10;
            this.currentPage -= 1;
            this.ListaMortiTempPagined = this.listMorti.slice(this.startPage, this.listMorti.length)            
            if(this.startPage <= 0){
                this.limitInfNumberMortiList=true
                
        }
    }
        
        
    }
    
    
}
