import { MonumentiResponse } from "@/models/MonumentiResponse";
import { AxiosError, AxiosResponse } from "axios";
import Vue from "vue";
import { TombaResponse } from "../models/TombaResponse"


class ApiService extends Vue {

    constructor() {
        super();
    }

    toQueryString(params: any) {
        
        var queryString = Object.keys(params)
            .map(function (key) {
                return key + "=" + params[key];
            })
            .join("&");

        return queryString;
    }
    getTomba(data:any): Promise<AxiosResponse<TombaResponse>> {
        //cemeteryId: string, totemId: string, securityToken: string, familyName: string, firstName: string
        var self = this;

        var params = {
            cemeteryId: data.cemeteryId,
            totemId: data.totemId,
            securityToken: data.securityToken,
            familyName: data.familyName,
            firstName: data.firstName,
            graveId : data.graveId
        };

        if(typeof params.firstName ===  'undefined'){
            delete params.firstName
        }
        if(typeof params.graveId === 'undefined'){
            delete params.graveId
        }
        
        var qs = this.toQueryString(params);
        

        return new Promise((resolve, reject) => {
            self.$http
                .get<TombaResponse>(`WebInfoCim/Default.aspx?${qs}`)
                .then(result => {
                    resolve(result);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    reject(err);
                });
        });
    }
    getMonumento(data:any): Promise<AxiosResponse<MonumentiResponse>> {
        
        var params = {
            cemeteryId: data.cemeteryId,
            totemId: data.totemId,
            securityToken: data.securityToken,
            codPoi: data.codPoi
        };
           
        var qs = this.toQueryString(params);

        return new Promise((resolve, reject) => {
            this.$http
                .get<MonumentiResponse>(`WebPoiCim/Default.aspx?${qs}`)
                .then(result => {
                    resolve(result);
                })
                .catch((err: AxiosError) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

}
const api = new ApiService();
export default api;